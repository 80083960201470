import { useEffect, useState } from "react";
import { components } from "../../../api/schema";
import SwitchBox, { THEMES } from "../../../components/SwitchBox";
import Tooltip from "../../../components/Tooltip";
import AutomationButtonDisabledTooltip from "../../../components/WorkloadStatusByNamespace/Columns/AutomationButtonDisabledTooltip";
import useIsAutomationButtonDisabled from "../../../components/WorkloadStatusByNamespace/Columns/useIsAutomationButtonDisabled";
import { ScaleOpsProduct } from "../../../utils/typesUtils";
import useHasMultiProductLayout from "../../../utils/useHasMultiProductLayout";
import useUpdateAutomation from "./mutation/useUpdateAutomation";

interface Props {
  isAutomate: boolean;
  setIsAutomate: (isAutomate: boolean) => void;
  fetchWorkloads: () => void;
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  scaleOpsProduct?: ScaleOpsProduct;
  autoFieldToUpdate: "auto" | "hpaAuto" | "rightSizeAuto";
}

const AutomationSwitch = ({
  isAutomate,
  setIsAutomate,
  fetchWorkloads,
  selectedWorkload,
  scaleOpsProduct,
  autoFieldToUpdate,
}: Props) => {
  const hasMultiProduct = useHasMultiProductLayout();
  const isAutomationButtonDisabled = useIsAutomationButtonDisabled({ params: selectedWorkload });
  const updateAutomation = useUpdateAutomation(fetchWorkloads);

  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    switch (true) {
      case !hasMultiProduct:
        setTitle("Automate");
        break;
      case scaleOpsProduct === ScaleOpsProduct.HPA && hasMultiProduct:
        setTitle("Automate HPA");
        break;
      case scaleOpsProduct !== ScaleOpsProduct.HPA && hasMultiProduct:
        setTitle("Automate rightsize");
        break;
    }
  }, [hasMultiProduct, scaleOpsProduct]);

  const handleClick = () => {
    updateAutomation.mutate({
      id: selectedWorkload.id,
      namespace: selectedWorkload.namespace,
      workloadName: selectedWorkload.workloadName,
      workloadType: selectedWorkload.type,
      state: !isAutomate,
      scaleOpsProduct,
      autoFieldToUpdate,
    });
    setIsAutomate(!isAutomate);
  };

  const isAutomationExcluded = selectedWorkload.isAutomationExcluded;

  return (
    <Tooltip
      title={<AutomationButtonDisabledTooltip params={selectedWorkload} />}
      maxWidth={500}
      disabled={!isAutomationButtonDisabled}
    >
      <SwitchBox
        title={title}
        handleClick={handleClick}
        checked={isAutomate}
        disabled={isAutomationButtonDisabled}
        notAllowed={isAutomationExcluded}
        theme={THEMES.Purple}
      />
    </Tooltip>
  );
};

export default AutomationSwitch;
