import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Typography } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useMainContext } from "../MainContext";
import MainMenuItem, { MainMenuItemSize } from "./MainMenuItem";

const ARROW_ICON_SIZE = 18;

type URL = {
  name: string;
  url: string;
  variant?: "caption" | "body2";
};

interface Props {
  baseUrl: URL;
  urls: URL[];
  title: React.ReactNode;
  icon?: React.ReactElement;
  MainMenuItemSize?: MainMenuItemSize;
  ItemsWrapperClassName?: string;
  openByDefault?: boolean;
}

const MultiMenu = ({ baseUrl, urls, title, icon, MainMenuItemSize, ItemsWrapperClassName, openByDefault }: Props) => {
  const URL_ARRAY_VALUES = [baseUrl.url, ...urls.map((url) => url.url)];

  const location = useLocation();
  const navigate = useNavigate();
  const { currentCluster } = useMainContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedClusterNamesHistory, setSelectedClusterNamesHistory] = useState<string[]>([]);
  const [isInnerItemsSelected, setIsInnerItemsSelected] = useState(false);

  useEffect(() => {
    if (openByDefault) {
      setIsOpen(true);
    }
  }, [openByDefault]);

  useEffect(() => {
    if (currentCluster && !selectedClusterNamesHistory.includes(currentCluster)) {
      setSelectedClusterNamesHistory([...selectedClusterNamesHistory, currentCluster]);
    }
    if (selectedClusterNamesHistory.length > 0 && currentCluster) {
      toast.success(`Cluster changed successfully to ${currentCluster}`);
    }
  }, [currentCluster]);

  useEffect(() => {
    const isInnerItemsSelectedValue = URL_ARRAY_VALUES.some((url) => location.pathname.startsWith(url));
    setIsInnerItemsSelected(isInnerItemsSelectedValue);
    isInnerItemsSelectedValue && setIsOpen(isInnerItemsSelectedValue);
  }, [location.pathname]);

  return (
    <div
      className={clsx("flex flex-col", {
        "gap-2": isOpen,
      })}
    >
      <Typography
        onClick={() => {
          const isUrlIncluded = URL_ARRAY_VALUES.some((url) => location.pathname.startsWith(url));
          if (isUrlIncluded || isOpen) {
            setIsOpen(!isOpen);
          } else {
            navigate(baseUrl.url);
          }
        }}
        className={clsx("flex px-4 py-1 rounded-lg cursor-pointer items-center")}
        variant={baseUrl.variant ?? "body2"}
      >
        <div
          className={clsx("grow flex justify-start items-center gap-2", {
            underline: isInnerItemsSelected,
          })}
        >
          {icon}
          {title}
        </div>
        <div>
          {isOpen ? (
            <KeyboardArrowUp
              sx={{
                width: ARROW_ICON_SIZE,
                height: ARROW_ICON_SIZE,
              }}
            />
          ) : (
            <KeyboardArrowDown
              sx={{
                width: ARROW_ICON_SIZE,
                height: ARROW_ICON_SIZE,
              }}
            />
          )}
        </div>
      </Typography>
      <div
        className={clsx(ItemsWrapperClassName, "ml-[25px] border-l border-text-lightBlack pl-4 flex flex-col gap-2")}
        style={{
          maxHeight: isOpen ? "100vh" : 0,
          overflow: "hidden",
          transition: isOpen ? "max-height 0.2s ease-in" : undefined,
        }}
      >
        {urls.map((url) => (
          <MainMenuItem title={url.name} to={url.url} variant="caption" size={MainMenuItemSize} />
        ))}
      </div>
    </div>
  );
};

export default MultiMenu;
