import { Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { SCALEOPS_COLORS } from "../colors";
import { IconProps } from "../Icons/utils/utils";
import useWindowSize from "./useWindowSize";

interface ProgressBarProps extends IconProps {
  percentage: number;
  mainFill?: string;
  backgroundFill?: string;
  hasDisplayNumber?: boolean;
  wrapperClassName?: string;
}

const ProgressBar = (props: ProgressBarProps) => {
  const {
    percentage = 0,
    mainFill = SCALEOPS_COLORS.main.blue,
    backgroundFill = SCALEOPS_COLORS.border,
    hasDisplayNumber = true,
    height = 12,
    rx = 5,
    width = "100%",
    wrapperClassName,
    className,
  } = props;

  const size = useWindowSize();

  useEffect(() => {
    setWidthPercentage(0);
    setTimeout(() => {
      setWidthPercentage(percentage);
    }, 0);
  }, [size.width, percentage]);

  const [widthPercentage, setWidthPercentage] = useState<number>(0);
  const maskId = `mask-${Math.round(percentage)}`;

  useEffect(() => {
    let percentageValue = 0;
    switch (true) {
      case percentage < 0:
        percentageValue = 0;
        break;
      case percentage > 100:
        percentageValue = 100;
        break;
      default:
        percentageValue = percentage;
    }

    setWidthPercentage(percentageValue);
  }, [percentage]);

  const spring = useSpring({
    from: { number: 0 },
    number: widthPercentage,
  });

  return (
    <div className={clsx(wrapperClassName, "flex items-center justify-center gap-2")}>
      {hasDisplayNumber && (
        <Typography variant="body2">
          <animated.span>
            {spring.number
              .to((n) => n.toFixed(0))
              .to((n) => {
                return `${n}%`;
              })}
          </animated.span>
        </Typography>
      )}
      <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 100% 100%" {...props}>
        <mask id={`${maskId}`} x="0" y="0" width="100%" height="100%" rx={rx}>
          <animated.rect
            x="0"
            y="0"
            width={spring.number
              .to((n) => n.toFixed(0))
              .to((n) => {
                return `${n}%`;
              })}
            height={height}
            fill="white"
          />
        </mask>
        <rect width="100%" height="100%" fill={backgroundFill} rx={rx} />
        <rect width="100%" height="100%" mask={`url(#${maskId})`} fill={mainFill} rx={rx} />
      </svg>
    </div>
  );
};

export default ProgressBar;
