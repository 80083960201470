import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  GetUnevictableData,
  GetUnevictableDataResponse,
  SetUnevictableAuto,
  SetUnevictableAutoParams,
} from "../../api/fetcher";

const TOAST_SETTINGS = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 1000,
  style: {
    width: 400,
  },
};

const { queryFn: setUnevictableAutoQueryFn } = SetUnevictableAuto();
const { queryKey: unevictableQueryKey } = GetUnevictableData();

export const useAutomatePods = () => {
  const queryClient = useQueryClient();

  return useMutation((params: SetUnevictableAutoParams) => setUnevictableAutoQueryFn(params), {
    onMutate: async (variables) => {
      await queryClient.cancelQueries([unevictableQueryKey]);
      queryClient.setQueryData([unevictableQueryKey], (oldData: GetUnevictableDataResponse | undefined) => {
        return {
          ...oldData,
          variables,
        };
      });
    },
    onError: (error: Error, variables) => {
      toast.error(`Error ${variables ? "Automated" : "Un-Automated"} pods without owners`, TOAST_SETTINGS);
      console.log(`Error: ${error.message}`);
    },
    onSuccess: (_, variables) => {
      toast.success(`${variables ? "Automated" : "Un-Automated"} pods without owners`, TOAST_SETTINGS);
    },
    onSettled: () => {
      [unevictableQueryKey].forEach((key) => {
        queryClient.invalidateQueries([key]).catch((error) => console.log(error));
      });
    },
  });
};
