import MultipleCubesIcon from "../Icons/MultipleCubesIcon";
import RightSizingIcon from "../Icons/RightSizingIcon";
import UpAndDownCircleIcon from "../Icons/UpAndDownCircleIcon";
import { HAS_UNEVICTABLE_MENU } from "../utils/developmentFeatureFlags";
import MainMenuItem, { MainMenuItemSize } from "./MainMenuItem";
import MenuHorizontalRule from "./MenuHorizontalRule";
import MultiMenu from "./MultiMenu";

const MultiProductMenu = () => (
  <>
    <MenuHorizontalRule title="Optimization" />
    <MultiMenu
      baseUrl={{ name: "Rightsize", url: "/" }}
      urls={[
        { name: "Workloads", url: "/" },
        { name: "Policies", url: "/rightsize/policies" },
      ]}
      icon={<RightSizingIcon />}
      title="Rightsize"
      MainMenuItemSize={MainMenuItemSize.Small}
    />
    <MultiMenu
      baseUrl={{ name: "HPA", url: "/hpa/workloads" }}
      urls={[
        { name: "Workloads", url: "/hpa/workloads" },
        { name: "Policies", url: "/hpa/policies" },
      ]}
      icon={<UpAndDownCircleIcon />}
      title="HPA"
      MainMenuItemSize={MainMenuItemSize.Small}
    />
    {HAS_UNEVICTABLE_MENU && <MainMenuItem icon={<MultipleCubesIcon />} title="Pod Placement" to="/unevictable" />}
    <MenuHorizontalRule />
  </>
);

export default MultiProductMenu;
