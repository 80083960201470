import clsx from "clsx";
import { memo } from "react";
import PartialBorders from "../../components/PartialBorders";
import RunningNumberBox from "../../pages/Overview/TopOverviewSection/RunningNumberBox";
import WasteVsOptimizedBarCharts from "../../pages/Overview/TopOverviewSection/WasteVsOptimizedBarCharts";
import { GRAY_SIDE_TEXT, MAIN_SECTION_CLASSNAME } from "../../utils/styleUtils";
import PercentagePieChart from "../PercentagePieChart";
//import useWindowSize from "../useWindowSize";
import Tab from "../../components/Tab";
import { TabSelectedTime } from "../../utils/tabUtils";
import useWindowSize from "../useWindowSize";

const MAIN_GAP = "gap-4";
const MAIN_WIDTH = "w-[25%]";
const CENTER_FLEX_ITEMS = "flex flex-col justify-center items-center";

const getXDaysAgoTimestamp = (x: number) => Math.floor(Date.now() / 1000) - 60 * 60 * 24 * x;

interface Props {
  monthlyCost: number;
  totalOptimizedPods: number;
  totalUnOptimizedPods: number;
  wastedSpend: number;
  blockedNodes: number;
  disableAnimation?: boolean;
  selectedTime: TabSelectedTime;
  setSelectedTime: (selectedTime: TabSelectedTime) => void;
  setStartTime: (startTime?: number) => void;
  isLoading?: boolean;
}

const TopSection: React.FC<Props> = memo(
  ({
    monthlyCost,
    totalOptimizedPods,
    totalUnOptimizedPods,
    wastedSpend,
    blockedNodes,
    disableAnimation,
    selectedTime,
    setSelectedTime,
    setStartTime,
    isLoading = false,
  }: Props) => {
    const size = useWindowSize();

    return (
      <div className="flex flex-col">
        <div className={"flex gap-[3px]"}>
          <Tab
            name="Live"
            dataTestId="top-unevictable-live-tab"
            isSelected={selectedTime === TabSelectedTime.LIVE}
            onClick={() => {
              setSelectedTime(TabSelectedTime.LIVE);
              setStartTime(undefined);
            }}
          />
          <Tab
            name="1 day"
            dataTestId="top-unevictable-30-days-tab"
            isSelected={selectedTime === TabSelectedTime.LAST_1_DAY}
            onClick={() => {
              setSelectedTime(TabSelectedTime.LAST_1_DAY);
              setStartTime(getXDaysAgoTimestamp(1));
            }}
          />
        </div>

        <div className={clsx("mt-[0px] shadow-none", MAIN_SECTION_CLASSNAME)}>
          <div className={MAIN_WIDTH}>
            <PartialBorders>
              <div className={CENTER_FLEX_ITEMS}>
                <RunningNumberBox
                  value={monthlyCost}
                  title={
                    <>
                      Cost <span className={GRAY_SIDE_TEXT}>(monthly)</span>
                    </>
                  }
                  valueDataTestId="top-unevictable-monthly-cost-value"
                  numberVariant="h2"
                  prefix="$"
                  numberClassName="text-text-lightBlack"
                  disableAnimation={disableAnimation}
                  isLoading={isLoading}
                />
              </div>
            </PartialBorders>
          </div>
          <div className={MAIN_WIDTH}>
            <PartialBorders left>
              <div className={CENTER_FLEX_ITEMS}>
                <RunningNumberBox
                  title={
                    <>
                      Blocked Nodes <span className={GRAY_SIDE_TEXT}>(unevictable)</span>
                    </>
                  }
                  valueDataTestId="top-unevictable-blocked-nodes"
                  value={blockedNodes}
                  numberVariant="h2"
                  numberClassName="text-text-lightBlack"
                  disableAnimation={disableAnimation}
                  isLoading={isLoading}
                />
              </div>
            </PartialBorders>
          </div>
          <div className={MAIN_WIDTH}>
            <PartialBorders left right>
              <div className={CENTER_FLEX_ITEMS}>
                <RunningNumberBox
                  title="Wasted spend"
                  valueDataTestId="top-unevictable-wasted-spend-value"
                  value={wastedSpend}
                  suffix="%"
                  numberVariant="h2"
                  numberClassName={clsx({ "text-main-red": wastedSpend > 0, "text-text-lightBlack": wastedSpend <= 0 })}
                  className={clsx(MAIN_GAP)}
                  disableAnimation={disableAnimation}
                  isLoading={isLoading}
                />
                <WasteVsOptimizedBarCharts
                  waste={wastedSpend}
                  optimized={100 - wastedSpend}
                  disableTooltip={wastedSpend <= 0}
                  disableAnimation={disableAnimation}
                />
              </div>
            </PartialBorders>
          </div>
          <div className={MAIN_WIDTH}>
            <PercentagePieChart
              xLabel="optimized"
              yLabel="un-optimized"
              title="Optimized"
              subtitle="Unevictable pods"
              xValue={totalOptimizedPods}
              yValue={totalUnOptimizedPods}
              showLabels={!!(size.width && size.width > 1560)}
              disableAnimation={disableAnimation}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default TopSection;
