import clsx from "clsx";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import InfoIcon from "../Icons/InfoIcon";
import { SELECTED_CONTAINER_KEY } from "../pages/Overview/PolicyTuning/ContainerFilter";
import { FeatureEnabled } from "../utils/FeaturesHelper";
import { GAPPED_COLUMN } from "../utils/styleUtils";
import { ScaleOpsProduct } from "../utils/typesUtils";
import useHasMultiProductLayout from "../utils/useHasMultiProductLayout";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "./Tab";
import Tooltip from "./Tooltip";
import TopOverviewContainer from "./TopOverviewContainer";
import OverviewAlertBars from "./WorkloadStatusByNamespace/OverviewAlertBars";
import { CustomTooltipPayload } from "./WorkloadStatusByNamespace/ResourceOverTime/CustomTooltip";
import ResourceOverTimeContainer from "./WorkloadStatusByNamespace/ResourceOverTime/ResourceOverTimeContainer";
import { ChartComponents } from "./WorkloadStatusByNamespace/ResourceOverTime/utils";
import SnapshotAlertBar from "./WorkloadStatusByNamespace/SnapshotAlertBar";
import WorkloadStatusByNamespace from "./WorkloadStatusByNamespace/WorkloadStatusByNamespace";
import { OVERVIEW_TAB_QUERY_KEY, OverviewTableTabOptions } from "./WorkloadStatusByNamespace/overviewUtils";
import WorkloadsAggregation from "./WorkloadsAggregation/WorkloadsAggregation";
import * as componentUtils from "./componentUtils/overview";

const INFO_ICON_SIZE = 14;
export const HAS_WORKLOADS_AGGREGATION_TAB = true;

interface Props {
  scaleOpsProduct?: ScaleOpsProduct;
}

export default function WorkloadsDashboard({ scaleOpsProduct }: Props) {
  const hasMultiProductLayout = useHasMultiProductLayout();

  const [displayProductName, setDisplayProductName] = useState<string | undefined>(undefined);
  const [disableAnimation, setDisableAnimation] = useState(false);
  const [showAllocatable] = useState<boolean>(!FeatureEnabled("DemoVersion"));
  const [selectedTab, setSelectedTab] = useQueryParam(
    OVERVIEW_TAB_QUERY_KEY,
    withDefault(StringParam, OverviewTableTabOptions.Workloads)
  );
  const [, setSelectedContainer] = useQueryParam(SELECTED_CONTAINER_KEY);

  useEffect(() => {
    setSelectedContainer(undefined);
  }, []);

  useEffect(() => {
    if (hasMultiProductLayout) {
      const productName = scaleOpsProduct === ScaleOpsProduct.HPA ? "HPA" : "rightsize";
      setDisplayProductName(productName);
    } else {
      setDisplayProductName(undefined);
    }
  }, [hasMultiProductLayout]);

  return componentUtils.windowBlock(
    "",
    <div className={clsx("min-w-[1200px]", GAPPED_COLUMN)}>
      <SnapshotAlertBar />
      <OverviewAlertBars />
      <TopOverviewContainer disableAnimation={disableAnimation} scaleOpsProduct={scaleOpsProduct} />
      <ResourceOverTimeContainer
        showRecommended
        showAllocatable={showAllocatable}
        getWasteValue={(payload: CustomTooltipPayload) => payload.request - payload.recommended}
        includedChartComponents={[
          ChartComponents.Usage,
          ChartComponents.Request,
          ChartComponents.WasteFromRequestToRecommended,
          ChartComponents.Waste,
          ChartComponents.Recommended,
          ChartComponents.Allocatable,
        ]}
        defaultSelectedChartComponents={[
          ChartComponents.Usage,
          ChartComponents.Request,
          ChartComponents.WasteFromRequestToRecommended,
          ChartComponents.Waste,
          ChartComponents.Recommended,
          // ChartComponents.Allocatable,
        ]}
        disableAnimation={disableAnimation}
        scaleOpsProduct={scaleOpsProduct}
      />
      <div className={clsx("relative", { "mt-[32px]": HAS_WORKLOADS_AGGREGATION_TAB })}>
        {HAS_WORKLOADS_AGGREGATION_TAB && (
          <div className={clsx(TABS_CONTAINER_CLASS_NAME, "top-[-30px] w-full")}>
            {Object.entries(OverviewTableTabOptions).map(([key, value]) => {
              return (
                <Tab
                  key={key}
                  isSelected={selectedTab === value}
                  onClick={() => {
                    setSelectedTab(value);
                  }}
                  name={
                    <Tooltip
                      className="flex items-center gap-1"
                      title={
                        <>
                          Deployment{" "}
                          <b>
                            {displayProductName} {value.toLowerCase()}
                          </b>
                        </>
                      }
                      disabled={!displayProductName}
                      maxWidth={500}
                    >
                      <span>{value}</span>
                      {displayProductName && <InfoIcon width={INFO_ICON_SIZE} height={INFO_ICON_SIZE} />}
                    </Tooltip>
                  }
                  dataTestId={`node-cost-chart-${key}-tab`}
                />
              );
            })}
          </div>
        )}
        {selectedTab === OverviewTableTabOptions.Workloads && (
          <WorkloadStatusByNamespace setDisableAnimation={setDisableAnimation} scaleOpsProduct={scaleOpsProduct} />
        )}
        {selectedTab === OverviewTableTabOptions.Aggregation && (
          <WorkloadsAggregation scaleOpsProduct={scaleOpsProduct} />
        )}
      </div>
    </div>
  );
}
